import React, { useCallback, useMemo } from 'react'
import { css, useTheme } from '@emotion/react'
import { HeadingV1 } from './heading'
import { Section, Inner } from './layout'
import { ZoomingImage } from './zooming-image'
import { Slidable } from './slidable'
import { I18nLink } from './i18n-link'

export type NewsItem = {
  id: string
  title: string
  summary: string
  date?: string
  category: {
    name: string
  } | null
  featuredImage: {
    src: string
  } | null
}

export type NewsItemProps = {
  item: NewsItem
}

export const NewsSectionItem: React.FC<NewsItemProps> = ({ item }) => {
  const { v1: theme } = useTheme()
  const date = useMemo(() => (item.date ? new Date(item.date) : null), [item.date])

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;

        @media (min-width: ${theme.breakpoint}) {
          gap: 20px;
        }
      `}
    >
      <div>
        <I18nLink to={`/news/${item.id}`}>
          <ZoomingImage src={item.featuredImage?.src ?? ''} minWidth={250} minHeight={150} />
        </I18nLink>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div>
          {item.category && (
            <span
              css={css`
                ${theme.text.sm};
                ${theme.fontWeight.medium};
                display: inline-flex;
                background-color: ${theme.baseColor.blue};
                color: ${theme.baseColor.white};
                height: 1.25rem;
                padding: 0 0.5rem;
                align-items: center;
              `}
            >
              <span
                css={css`
                  position: relative;
                  top: 0.05rem;
                `}
              >
                {item.category.name}
              </span>
            </span>
          )}
        </div>
        <div>
          {date && (
            <span
              css={css`
                ${theme.text.sm};
                ${theme.fontWeight.medium};
              `}
            >
              {date.getFullYear()}.{date.getMonth() + 1}.{date.getDate()}
            </span>
          )}
        </div>
      </div>
      <div>
        <div
          css={css`
            ${theme.text.base};
            ${theme.fontWeight.medium};
          `}
        >
          {item.title}
        </div>
      </div>
    </div>
  )
}

export type NewsSectionProps = {
  items: NewsItem[]
  aboveContent?: React.ReactNode
  belowContent?: React.ReactNode
  compact?: boolean
  className?: string
  title?: string
}

export const NewsSection: React.FC<NewsSectionProps> = ({
  items,
  aboveContent,
  belowContent,
  compact,
  className,
  title,
}) => {
  const { v1: theme } = useTheme()

  return (
    <Section $compact={compact} className={className}>
      <Inner>
        <HeadingV1>{title ?? 'NEWS'}</HeadingV1>
        {aboveContent && (
          <div
            css={css`
              margin-bottom: 1.875rem;
            `}
          >
            {aboveContent}
          </div>
        )}
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            align-items: start;
            gap: 50px 32px;

            @media (min-width: ${theme.breakpoint}) {
              grid-template-columns: repeat(3, 1fr);
            }
          `}
        >
          {items.map((item) => (
            <div key={item.id}>
              <NewsSectionItem item={item} />
            </div>
          ))}
        </div>
        {belowContent && (
          <div
            css={css`
              margin-top: 3.125rem;
            `}
          >
            {belowContent}
          </div>
        )}
      </Inner>
    </Section>
  )
}

export type FeaturedNewsProps = {
  items: NewsItem[]
  className?: string
}

export const FeaturedNews: React.FC<FeaturedNewsProps> = ({ items, className }) => {
  const render = useCallback((item: NewsItem) => <NewsSectionItem item={item} />, [])

  return (
    <Slidable
      className={className}
      items={items}
      itemGapS={14}
      itemGapL={32}
      itemWidthS={250}
      itemWidthL={320}
      render={render}
    />
  )
}
