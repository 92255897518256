import { createClient } from 'microcms-js-sdk'
import { useMemo } from 'react'
import { truncate, logger, isDefaultLocale } from '../helpers'

declare global {
  interface Window {
    __MICRO_CMS_DATA__?: BlogContent<BlogContentString> | null
  }
}

const clientFactory = () => {
  const serviceDomain = process.env.REACT_APP_MICRO_CMS_SERVICE_DOMAIN
  const apiKey = process.env.REACT_APP_MICRO_CMS_API_KEY

  if (serviceDomain == null || apiKey == null) {
    throw new Error('useMicroCms: Missing serviceDomain or apiKey or both of them')
  }

  return createClient({ serviceDomain, apiKey })
}

export type Summary = {
  id: string
  title: string
  summary: string
  category: {
    name: string
  } | null
  date?: string
  featuredImage: {
    src: string
  } | null
}

export type ContentList<T> = {
  limit: number
  offset: number
  totalCount: number
  contents: T[]
}

export type Blog = {
  id: string
  title: string
  content: string
  category: {
    name: string
  } | null
  date?: string
  featuredImage: {
    src: string
  } | null
}

export type CaseAndVoice = {
  id: string
  customer: string
  body: string
  date?: string
  featuredImage: {
    src: string
  } | null
}

type BlogContentObject = {
  content: {
    contents: {
      type: string
      value: {
        type: string
        value: string
      }[]
    }[]
  }
}

type BlogContentString = {
  content: string
}

type BlogContentI18n<T> = {
  title?: string
  content: T[]
}

type BlogContentBase<T> = BlogContentI18n<T> & {
  id: string
  category?: {
    name: string
  } | null
  eyecatch?: {
    url: string
  } | null
  publishedAt?: string
}

type BlogContent<T> = BlogContentBase<T> & Record<string, BlogContentI18n<T> | undefined>

type CavContentI18n = {
  customer: string
  body: string
}

type CavContentBase = CavContentI18n & {
  id: string
  eyecatch?: {
    url: string
  } | null
  publishedAt?: string
}

type CavContent = CavContentBase & Record<string, CavContentI18n | undefined>

const getContent = <T extends Record<string, unknown>, U extends keyof T = keyof T>(
  data: T,
  keys: U[],
  locale?: string | null,
): T => {
  const main = (locale ? data[locale] ?? data : data) as Record<U, unknown>
  const sub = data as Record<U, unknown>

  return keys.reduce(
    (prev, next) => {
      const mainValue = main[next]
      const subValue = sub[next]

      if (Array.isArray(mainValue)) {
        return {
          ...prev,
          [next]: mainValue.length > 0 ? mainValue : subValue,
        }
      }

      return {
        ...prev,
        [next]: mainValue ? mainValue : subValue,
      }
    },
    {} as Record<U, unknown>,
  ) as T
}

export type ListItem = {
  id: string
  name: string
}

const createMicroCmsClient = () => {
  const client = clientFactory()

  const getCategories = async (names: string[]): Promise<ListItem[]> => {
    const categories = await client.getList({
      endpoint: 'categories',
    })

    logger.debug({ categories })

    return names
      .map((name) => {
        const cat = categories.contents.find((cat_) => cat_.name === name)

        if (cat == null) {
          return null
        }

        return {
          id: cat.id,
          name: cat.name,
        }
      })
      .filter(<T>(c: T): c is NonNullable<T> => c != null)
  }

  const getProjects = async (names: string[]): Promise<ListItem[]> => {
    const projects = await client.getList({
      endpoint: 'project',
    })

    logger.debug({ projects })

    return names
      .map((name) => {
        const project = projects.contents.find((project_) => project_.name === name)

        if (project == null) {
          return null
        }

        return {
          id: project.id,
          name: project.name,
        }
      })
      .filter(<T>(c: T): c is NonNullable<T> => c != null)
  }

  const getBlogSummaries = async ({
    categoryId,
    projectId,
    locale,
    limit,
    offset,
    featured,
    filterByLocale = true,
  }: {
    categoryId?: string | null | undefined
    projectId?: string | null | undefined
    locale?: string | null | undefined
    limit?: number | undefined
    offset?: number | undefined
    featured?: boolean
    filterByLocale?: boolean
  }): Promise<ContentList<Summary>> => {
    const filters: string[] = []

    if (categoryId != null) {
      filters.push(`category[equals]${categoryId}`)
    }

    if (projectId != null) {
      filters.push(`project[equals]${projectId}`)
    }

    if (featured != null) {
      filters.push(`featured[equals]${featured ? 'true' : 'false'}`)
    }

    if (filterByLocale && !isDefaultLocale(locale)) {
      filters.push(`${locale}.title[exists]`)
    }

    const queries = {
      filters: filters.length > 0 ? filters.join('[and]') : undefined,
      limit,
      offset,
      orders: '-publishedAt',
    }

    const data = await client.getList<BlogContent<BlogContentString | BlogContentObject>>({
      endpoint: 'blogs',
      queries,
    })

    logger.debug({
      params: {
        categoryId,
        locale,
        limit,
        offset,
        featured,
      },
      queries,
      blogs: data,
    })

    const contents = data.contents.map((d) => {
      const { title } = getContent(d, ['title', 'content'], locale)
      // NOTE: Temporary disabled.
      const summary = ''

      return {
        id: d.id,
        title: title ?? '',
        summary: truncate(summary, 64),
        date: d.publishedAt,
        category: d.category
          ? {
              name: d.category.name,
            }
          : null,
        featuredImage: d.eyecatch
          ? {
              src: d.eyecatch.url,
            }
          : null,
      }
    })

    return {
      limit: data.limit,
      offset: data.offset,
      totalCount: data.totalCount,
      contents,
    }
  }

  const getBlog = async (id: string, locale?: string | null | undefined): Promise<Blog> => {
    const data = await (async () => {
      if (window.__MICRO_CMS_DATA__) {
        logger.debug({ 'window.__MICRO_CMS_DATA__': window.__MICRO_CMS_DATA__ })

        const d = window.__MICRO_CMS_DATA__

        Reflect.deleteProperty(window, '__MICRO_CMS_DATA__')

        return d
      }

      return client.get<BlogContent<BlogContentString>>({
        endpoint: 'blogs',
        contentId: id,
      })
    })()

    const { title, content } = getContent(data, ['title', 'content'], locale)

    return {
      id: data.id,
      title: title ?? '',
      content: content.map((c) => c.content).join(''),
      category: data.category
        ? {
            name: data.category.name,
          }
        : null,
      date: data.publishedAt,
      featuredImage: data.eyecatch
        ? {
            src: data.eyecatch.url,
          }
        : null,
    }
  }

  const getCasesAndVoices = async (
    id: string,
    locale?: string | null | undefined,
  ): Promise<ContentList<CaseAndVoice>> => {
    const data = await client.getList<CavContent>({
      endpoint: 'cases-and-voices',
      queries: {
        filters: `serviceId[contains]${id}`,
      },
    })

    logger.debug({ 'cases-and-voices': data })

    const contents = data.contents.map((d) => {
      const { customer, body } = getContent(d, ['customer', 'body'], locale)

      return {
        id: d.id,
        customer,
        body,
        date: d.publishedAt,
        featuredImage: d.eyecatch
          ? {
              src: d.eyecatch.url,
            }
          : null,
      }
    })

    return {
      limit: data.limit,
      offset: data.offset,
      totalCount: data.totalCount,
      contents,
    }
  }

  const createBlogSummariesPlaceholder = (size: number) =>
    new Array(size).fill(null).map((_, i) => ({
      id: String(i),
      title: '...',
      summary: '...',
      category: {
        name: '...',
      },
      date: new Date().toISOString(),
      featuredImage: {
        src: '',
      },
    }))

  const createCasesAndVoicesPlaceholder = (size: number) =>
    new Array(size).fill(null).map((_, i) => ({
      id: String(i),
      customer: '...',
      body: '...',
      date: new Date().toISOString(),
      featuredImage: {
        src: '',
      },
    }))

  return {
    getCategories,
    getProjects,
    getBlogSummaries,
    getBlog,
    getCasesAndVoices,
    createBlogSummariesPlaceholder,
    createCasesAndVoicesPlaceholder,
  }
}

export const useMicroCms = () => {
  return useMemo(() => createMicroCmsClient(), [])
}
