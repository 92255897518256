import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { Helmet } from 'react-helmet-async'
import { useParams, useSearchParams } from 'react-router-dom'
import { Breadcrumbs } from '../components/breadcrumbs'
import { FooterV1 } from '../components/footer'
import { Header } from '../components/header'
import { NewsSection, NewsItem } from '../components/news-section'
import { OuterV1 } from '../components/layout'
import { useMicroCms } from '../hooks/use-micro-cms'
import { logger } from '../helpers'
import { useAppSelector } from '../hooks/use-app-selector'
import { selectProjects } from '../store/blogs'
import { Pagination, PageChangeHandler } from '../components/pagination'
import { darkBgClassName } from '../constants'
import { useTitle } from '../hooks/use-title'

export const SetonagiPage: React.FC = () => {
  const { locale } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const microCms = useMicroCms()
  const projects = useAppSelector(selectProjects)
  const [blogs, setBlogs] = useState<NewsItem[]>([])
  const [totalCount, setTotalCount] = useState<number>(-1)

  const currentOffset = parseInt(searchParams.get('offset') ?? '0', 10)
  const currentLimit = parseInt(searchParams.get('limit') ?? '9', 10)
  const project = useMemo(() => projects.find((project) => project.name === 'SETONAGI'), [projects])

  useEffect(() => {
    ;(async () => {
      if (project == null) {
        return
      }

      const { contents, totalCount } = await microCms.getBlogSummaries({
        projectId: project.id,
        locale,
        offset: currentOffset,
        limit: currentLimit,
      })

      setBlogs(contents)
      setTotalCount(totalCount)
    })().catch((error) => logger.error(error))
  }, [microCms, currentOffset, currentLimit, project, locale])

  const handlePageChange = useCallback<PageChangeHandler>(
    ({ offset }) => {
      setSearchParams({
        offset: String(offset),
        limit: String(currentLimit),
      })
    },
    [setSearchParams, currentLimit],
  )

  const currentTitle = useTitle({
    ja: 'Setonagi',
    en: 'Setonagi',
  })

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
        <link rel="icon" href="/favicon.png" />
      </Helmet>
      <OuterV1>
        <Header darkBgSelector={`.${darkBgClassName}`} />
        <NewsSection
          title="SETONAGI"
          css={css`
            min-height: ${blogs.length === 0 ? '640px' : '0'};
          `}
          compact={true}
          items={blogs}
          belowContent={
            totalCount > currentLimit ? (
              <div>
                <Pagination
                  totalCount={totalCount}
                  limit={currentLimit}
                  offset={currentOffset}
                  arround={3}
                  onChange={handlePageChange}
                />
              </div>
            ) : null
          }
        />
        <Breadcrumbs
          items={[
            {
              name: 'TOP',
              href: '/',
            },
            {
              name: 'SETONAGI',
              href: '',
            },
          ]}
        />
        <FooterV1 className={darkBgClassName} />
      </OuterV1>
    </>
  )
}
