import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'

type Category = {
  id: string
  name: string
}

type Project = {
  id: string
  name: string
}

type BlogsState = {
  categories: Category[]
  projects: Project[]
}

const initialState: BlogsState = {
  categories: [],
  projects: [],
}

export const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload
    },
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.projects = action.payload
    },
  },
})

export const { setCategories, setProjects } = blogsSlice.actions

export const selectCategories = (state: RootState) => state.blogs.categories
export const selectProjects = (state: RootState) => state.blogs.projects

export default blogsSlice.reducer
